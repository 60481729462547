<template>
  <div class="export-bar">
    <div class="content">
      <Button
      v-if="showExportAll"
        @click="handleExport(true)">
        导出所有
      </Button>
      <Button
        @click="handleExport(false)">
        条件导出
      </Button>
    </div>

    <el-dialog
      :visible.sync="dialogVisible"
      title="确认导出"
      :destroy-on-close="true"
      :show-close="false"
    >
      <p>
        {{`是否确认导出`}}
      </p>
      <template slot="footer" class="dialog-footer">
        <Button style="margin-right:16px;" @click="dialogVisible = false">
          取消
        </Button>
        <Button type="primary" @click="confirmExport()">确定</Button>
      </template>
    </el-dialog>

  </div>
</template>

<script>

export default {
  name: 'confirm-export-bar',
  props: {
    showExportAll: {
      type: Boolean,
      default: true,
    },
    exportAllFn: {
      type: Function,
    },
    exportFn: {
      type: Function,
    },

  },
  data() {
    return {
      dialogVisible: false,
      exportAllFlag: false,
    };
  },

  methods: {

    async handleExport(exportAllFlag) {
      this.exportAllFlag = exportAllFlag;
      this.dialogVisible = true;
    },

    async confirmExport() {
      const loading = this.$loading({
        lock: true,
        text: '正在导出...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      });
      this.dialogVisible = false;
      if (this.exportAllFlag) {
        await this.exportAllFn();
      } else {
        await this.exportFn();
      }

      loading.close();
    },

  },
};
</script>

<style lang="less" scoped>
.export-bar {
  display: flex;
  flex-direction: row-reverse;
  margin-bottom: @spacingMD;
  .content > *:not(:last-child) {
    margin-right: @spacingMD;
  }
}
</style>
