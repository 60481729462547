import Vue from 'vue';
import AppDataTable from '../components/app-data-table';
import AppBlockFlex from '../components/app-block-flex';
import AppSpacing from '../components/app-spacing';
import AppTable from '../components/app-table';
import AppPagination from '../components/app-pagination';
import AppQueryBar from '../components/app-query-bar';
import AppExportBar from '../components/app-export-bar';
import AppModal from '../components/app-modal';
import ConfirmExportBar from '../components/confirm-export-bar';

Vue.component('AppDataTable', AppDataTable);
Vue.component('AppBlockFlex', AppBlockFlex);
Vue.component('AppSpacing', AppSpacing);
Vue.component('AppTable', AppTable);
Vue.component('AppPagination', AppPagination);
Vue.component('AppQueryBar', AppQueryBar);
Vue.component('AppExportBar', AppExportBar);
Vue.component('AppModal', AppModal);
Vue.component('ConfirmExportBar', ConfirmExportBar);
